export const hotels = [
    {
        id: 1,
        name: 'Pantelleria Dream',
        pack:'Dreaming pack',
        soldOut: false,
        link:'https://www.pantelleriaisland.it/the-island-festival.html',
        image:'pantelleria-dream.jpg',
    },
    {
        id: 2,
        name: 'Mursia Resort & Spa',
        pack:'Good Time Pack',
        soldOut: false,
        link:'https://www.pantelleriaisland.it/the-island-festival.html',
        image:'mursia.jpg',
    },
    {
        id: 3,
        name: 'Kuddie Rosse',
        pack:'TRAVEL PACK',
        soldOut: false,
        link:'https://www.pantelleriaisland.it/the-island-festival.html',
        image:'kuddie-rosse.jpg',
    },
]
