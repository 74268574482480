import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";

export const TicketCard = ({ticket}) => {
    return (
        <Stack spacing={4} className={ticket.bkg}
               sx={{px: 2, py: {xs: 4, md: 8}, borderRadius: '1.5rem', height: '100%'}}
               justifyContent={'space-between'}>
            <Typography textTransform={'uppercase'} lineHeight={'1.8rem'} fontFamily={'Blur'} textAlign={'center'}
                        variant={'h3'}
                        fontWeight={{xs: 700}} sx={{
                pb: {xs: 0, md: 2},
                //textShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }}>
                {ticket.title}
            </Typography>
            <Stack spacing={1} sx={ticket.bkg === 'card-2' && {
                background: '#FFFFFF',
                borderRadius: '1rem',
                //border: '1px solid #6278b7',
                px: 5,
                py: 3,
                //boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }}>
                {ticket.services.map((service, i) =>
                    service.type === 'separator' ?
                        <Stack direction={'row'} justifyContent={'stretch'} alignItems={'center'} spacing={2}
                               sx={{px: 2}} key={i}>
                            <Box sx={{width: '100%', height: '1px', background: '#005E2E'}}/>
                            <Typography color={'#005E2E'}>
                                or
                            </Typography>
                            <Box sx={{width: '100%', height: '1px', background: '#005E2E'}}/>
                        </Stack>
                        :
                        service.type === "optional" ?
                            <Typography textAlign={'center'} fontFamily={'Public Sans'} sx={{py:1}}>
                                optional
                            </Typography>
                            :
                            <Stack key={i} direction={'row'} sx={ticket.bkg !== 'card-2' && {
                                background: '#FFFFFF',
                                borderRadius: "1rem",
                                //border: '1px solid #6278b7',
                                px: 3,
                                py: 1.5,
                                //boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                            }} style={{height: '100%'}} justifyContent={'start'} alignItems={'center'} spacing={2}>
                                <Stack sx={{width: '2rem', height: '100%'}} justifyContent={'center'} className={"colored"}>
                                    <Box sx={{width: '2rem', height: '2rem'}} className={'neutral'}>
                                        <img style={{width: '100%', height: '100%', objectFit: 'contain'}}
                                             src={require(`../../assets/images/tickets/${service.icon}.png`)}
                                             alt={service.icon}/>
                                    </Box>
                                </Stack>
                                <Typography color={'#005E2E'} fontFamily={'Public Sans'} fontWeight={'400'}
                                            fontSize={'1rem'}>
                                    {service.text}
                                </Typography>
                            </Stack>
                )}
            </Stack>
            <Box sx={{textAlign: 'center'}}>
                <Button variant={'contained'}
                        sx={{fontFamily: 'Public Sans', fontWeight: '600', minWidth: '13rem', height: '3rem'}}
                        href={ticket.link} target={'_blank'}>
                    {ticket.cta}
                </Button>
            </Box>
        </Stack>
    )
}
