import React, {useState} from "react";
import {Box} from "@mui/material";
import {OtherArtists} from "./Artists/OtherArtists";
import {TopArtists} from "./Artists/TopArtists";
import {CardModal} from "./shared/CardModal";

export const Artists = () => {
    const [open,setOpen] = useState(false)
    const [index, setIndex] = useState(0)

    const handleOpen = (bool, i) => {
        setIndex(i)
        setOpen(bool)
    }

    return (
        <Box  id={'lineup'} sx={{pb:10}}>
            <CardModal open={open} handleOpen={handleOpen} type={'artists'} index={index}/>
            <Box sx={{pt:10}}>
                <Box sx={{borderBottom:'4px dotted white'}}/>
                <TopArtists/>
                <OtherArtists/>
            </Box>
        </Box>
    )
}
