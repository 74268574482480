import {createTheme, responsiveFontSizes} from "@mui/material";

export const islandTheme = responsiveFontSizes(createTheme({
        typography:{
            fontFamily:"'Termina', sans-serif"
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        background: "#133B1E",
                        boxShadow: 'none'
                    }
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        padding: 0
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '50rem',
                        paddingInline: '1.5rem'
                    }
                }
            },
            MuiPaper:{
                styleOverrides:{
                    root:{
                        backgroundImage:'none'
                    }
                }
            },
            MuiContainer: {
                styleOverrides: {
                    maxWidthXl: {
                        '&.MuiContainer-maxWidthXl': {
                            maxWidth: '115rem',
                        }
                    },
                    maxWidthLg: {
                        '&.MuiContainer-maxWidthLg': {
                            maxWidth: '90rem',
                        }
                    },
                    maxWidthMd: {
                        '&.MuiContainer-maxWidthMd': {
                            maxWidth: '78rem',
                        }
                    }
                }
            }
        },
        palette: {
            mode: "dark",
            background: {
                default: '#005E2E',
                paper:'#133B1E'
            },
            text:{
                primary: "#FFF4E7"
            },
            primary: {
                main: '#F38F00',
                contrastText:'#FFFFFF'
            },
            secondary:{
                main: '#FFF4E7'
            },
            action:{
                active:'#10683B'
            }
        }
    }
))
