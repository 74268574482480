import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import {content} from '../../assets/data/timetable.js'
import {Swiper, SwiperSlide} from "swiper/react";
import {TimeCard} from "./TimeCard";

export const TimetableMobile = () => {
    return (
        <Box sx={{pt:6}}>
            {content.map(day =>
                <Box sx={{pb:10}} key={day.day}>
                    <Stack direction={'row'} alignItems={'center'} sx={{pb:8}}>
                        <Box sx={{flexGrow:1, borderBottom:'4px dotted white'}}/>
                        <Typography variant={'h5'} textTransform={'uppercase'} sx={{px:3}} fontWeight={700}>
                            {day.day}
                        </Typography>
                        <Box sx={{flexGrow:1, borderBottom:'4px dotted white'}}/>
                    </Stack>
                    <Swiper
                        slidesPerView={1.1}
                        spaceBetween={3}
                        grabCursor={true}
                        pagination={{
                            clickable: true,
                        }}
                        className="mySwiper"
                        breakpoints={{
                            800:{
                                slidesPerView:2.1,
                                spaceBetween: 10
                            }
                        }}>
                        {day.sections.map((section, i) =>
                            <SwiperSlide key={i}>
                                <TimeCard section={section}/>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </Box>
            )}
        </Box>
    )
}
