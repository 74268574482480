import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";

export default function Ritual() {
    return(
        <Box sx={{py: 10, background:'#005E2E'}}>
            <Container maxWidth={'md'}>
                <Typography color={'#FFF4E7'} textAlign={'center'} variant={'h2'} fontFamily={'Blur'} fontWeight={{xs:700}} textTransform={'uppercase'} lineHeight={'3.4rem'}>
                    RITUAL
                    <br/>
                    Rethink the Future
                </Typography>
                <Grid container spacing={12} alignItems={'center'} sx={{pt:4}} direction={{xs: 'column-reverse', md:'row'}}>
                    <Grid item xs={12} md={7}>
                        <Typography fontFamily={'Public Sans'} fontWeight={'600'} color={'#FFF4E7'}>
                            To communicate the deeper identity of the festival, Islanders will perform a ritual during the first evening of The Island 2024 at the suggestive Scauri harbor.
                            <br/>
                            Participants will create a magical scenography lighting up candles under the statue of the goddess Tanit. Each candle will represent a wish for a better future.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <img style={{width:'100%', borderRadius:'1rem'}} src={require('../assets/images/ritual/ritual.jpg')}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}