import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";

export const PartnerBlock = ({partner}) => {
    return (
        <Grid item xs={partner.xs} md={partner.md} lg={partner.lg} sx={{display: partner.display ?? 'block'}}>
            {partner.title && <Box sx={{height: {xs: '2.2rem'}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Typography color={'#0B053E'} textAlign={'center'} textTransform={'uppercase'}
                            sx={{pb: 1, fontSize: 'min(2.5vw, 0.8rem)'}}>
                    {partner.title}
                </Typography>
            </Box>}
            {partner.type !=='empty' && partner.title && <Box sx={{borderTop: '1px solid #0B053E', pb: 1}}/>}
            <Stack direction={'row'} spacing={0} sx={{flexFlow: 'wrap'}} justifyContent={'start'}>
                {
                    partner.list.map((p, i) =>
                        <Box key={i} sx={{
                            width: `calc( 100% / ${partner.list.length})`,
                            minWidth: {xs:'30%', md:'auto'},
                            maxWidth:'15rem',
                            px: {xs: 0, md: 3},
                            height: `calc( 100% / ${partner.list.length})`,
                            margin:'auto',
                        }} component={p.link ? 'a' : 'span'} href={p.link ?? ''}>
                            <img style={{width: '100%', height: '100%', objectFit: 'contain'}}
                                 src={require(`../../assets/images/partners/${p.image}`)}/>
                        </Box>
                    )
                }
            </Stack>
        </Grid>
    )
}
