import React, {useRef} from "react";
import {Box, IconButton, Modal, Stack, Typography, Grow} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import experiences from '../../assets/data/experiences.json'
import {artists} from '../../assets/data/artists.js'
import {islandTheme} from "../../theme/islandTheme";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import {CloseRounded} from "@mui/icons-material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    '&:focus-visible':{
        outline:'none'
    }
};

export const CardModal = ({open, handleOpen, type, index}) => {
    const content = type === 'artists' ? artists : type === 'experiences' ? experiences : []
    const navigationNextRef = useRef(null)

    return (
        <Modal open={open} onClose={() => handleOpen(false, 0)}>
            <Box sx={style}>
                <Box sx={{ display:{xs:'block', md:'none'},position:'absolute', top:-40, left:'50%', transform:'translate(-50%, -50%)', zIndex:99}}>
                    <IconButton onClick={() => handleOpen(false, 0)}>
                        <CloseRounded sx={{color:islandTheme.palette.primary.main, fontSize:'2rem'}}/>
                    </IconButton>
                </Box>
                <Swiper
                    navigation={{
                        nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                    modules={[Navigation]}
                    slidesPerView={1.1}
                    spaceBetween={0}
                    centeredSlides={true}
                    grabCursor={true}
                    pagination={{
                        clickable: true,
                    }}
                    className="mySwiper"
                    initialSlide={index}
                    breakpoints={{
                        1200:{
                            slidesPerView:1.4,
                            spaceBetween: 200
                        }
                    }}
                >
                    {content.length > 0 && content.map((c, index) =>
                        <SwiperSlide key={index}>
                            <Grow in={true} exit={true}>
                                <Box sx={{ml: {xs:1, md:0}, borderRadius:'1.5rem', background: type === 'artists' ? '#23744A' :'#FFF4E7', position:'relative'}}>
                                    <Box sx={{ display:{xs:'none', md:'block'},position:'absolute', top:40, right:0, transform:'translate(-50%, -50%)', zIndex:99}}>
                                        <IconButton onClick={() => handleOpen(false, 0)}>
                                            <CloseRounded sx={{color:islandTheme.palette.primary.main, fontSize:'2.5rem'}}/>
                                        </IconButton>
                                    </Box>
                                    <Stack direction={{xs:'column', md:'row'}} sx={{p:2}} spacing={{xs:0, md:5}} alignItems={'center'}>
                                        <Box sx={{height: {xs:'12rem', md:'100%'}, width: {xs:'12rem', md:'100%'}, margin:'auto', pt: {xs:3, md:0}, pb: {xs:2, md:0}}}>
                                            <Box sx={{width:'100%', height:'100%', borderRadius:'1rem'}}>
                                                <img alt={"Experience"} style={{width:'100%', height:'100%', objectFit:'cover', borderRadius:'inherit'}} src={require(`../../assets/images/${type}/${c.image}.jpg`)}/>
                                            </Box>
                                        </Box>
                                        <Box sx={{height: {xs:'15rem', md:'100%'}, width: '100%'}}>
                                            <Typography color={type === 'experiences' ? '#005E2E': 'inherit'} textAlign={{xs:'center', md:'left'}} variant={'h6'} fontWeight={700} sx={{pt:{xs:0, md:6}}}>
                                                {c.name}
                                            </Typography>
                                            <Typography textAlign={{xs:'center', md:'left'}} sx={{pb: {xs:1, md:4}}} color={type === 'experiences' ? '#005E2E': 'inherit'}>
                                                {c.location}
                                            </Typography>
                                            <Typography fontFamily={'Public Sans'} sx={{opacity:'0.8',pb:4, fontSize:'0.80rem'}} textAlign={{xs:'center', md:'left'}} color={type === 'experiences' ? '#005E2E': 'inherit'}>
                                                {c.description}
                                            </Typography>
                                        </Box>
                                        <Box sx={{width:'6rem', margin:'auto', borderBottom:'3px solid rgba(255,255,255,0.5)', opacity:'0.55', display:{xs:'inline-block', md:'none'}}}/>
                                    </Stack>
                                </Box>
                            </Grow>
                        </SwiperSlide>
                    )}
                </Swiper>
            </Box>
        </Modal>
    )
}
