import React from "react";
import {Box} from "@mui/material";
import {Header} from "../components/Header";
import {VideoBox} from "../components/VideoBox";
import {TextScroll} from "../components/TextScroll";
import {Artists} from "../components/Artists";
import {Experiences} from "../components/Experiences";
import {Timetable} from "../components/Timetable";
import {CarbonNeutral} from "../components/CarbonNeutral";
import {HowToGet} from "../components/HowToGet";
import {Faq} from "../components/Faq";
import {Footer} from "../components/Footer";
import {Partners} from "../components/Partners";
import {Tickets} from "../components/Tickets";
import {ShareModal} from "../components/shared/ShareModal";
import {Hotels} from "../components/Hotels";
import Ritual from "../components/Ritual";

export const Home = () => {
    const [open, setOpen] = React.useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
    }

    return (
        <Box sx={{position: 'relative'}}>
            <ShareModal handleSetOpen={handleSetOpen} open={open}/>
            <Box id={'top'}/>
            <Header/>
            <Box className={'gradient'}>
                <VideoBox/>
                <TextScroll/>
                <Artists/>
            </Box>
            <Experiences/>
            <Ritual/>
            <Tickets/>
            <Hotels/>
            <CarbonNeutral/>
            <Timetable handleSetOpen={handleSetOpen}/>
            <HowToGet/>
            <Faq/>
            <Partners/>
            <Footer/>
        </Box>
    )
}
