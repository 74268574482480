import React from "react";
import {Box, Container, Grid} from "@mui/material";
import {TimeCard} from "./TimeCard";
import {SwiperSlide} from "swiper/react";

export const TimetableDay = ({content}) => {
    return (
        <Container maxWidth={'lg'}>
            <Grid container spacing={4} alignItems={'stretch'}>
                {content.sections.map((s, i) =>
                    <Grid key={i} item xs={4}>
                        <TimeCard section={s}/>
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}
