import React from "react";
import {Box, Button, Card, CardContent, CardMedia, Chip, Typography} from "@mui/material";

export const HotelCard = ({hotel}) => {

    return (
        <Card sx={{background: '#EAEAEA', borderRadius:'10px'}}>
            <CardMedia>
                <img style={{width: '100%'}} src={require(`../../assets/images/hotels/${hotel.image}`)}/>
            </CardMedia>
            <CardContent>
                <Box sx={{
                    borderRadius: '50rem',
                    border: '1px solid #F38F00',
                    px: 0.5,
                    py: 0.2,
                    width: {md: 'min(9rem, 100%)', xs: '12rem'}
                }}>
                    <Typography textAlign={'center'} variant={'body2'} fontWeight={'bold'}
                                textTransform={'uppercase'}
                                fontFamily={'Public Sans'} color={'primary'}>{hotel.pack}</Typography>
                </Box>
                <Typography color={'#005E2E'} variant={'h5'} fontWeight={'bold'}
                            sx={{pt: 2, minHeight: {sm:'5rem', xl:'4rem', xs:'4rem'}}}>{hotel.name}</Typography>
                <Button disabled={hotel.soldOut} href={hotel.link} target={'_blank'} startIcon={hotel.soldOut &&
                    <img style={{width: '1.2rem'}} src={require('../../assets/images/hotels/X.png')}/>}
                        variant={'contained'}
                        sx={{fontFamily: 'Public Sans', fontWeight: 'bold', fontSize: '1rem'}}>
                    {hotel.soldOut ? 'SOLD OUT' : 'BOOK NOW'}
                </Button>
            </CardContent>
        </Card>
    )
}
