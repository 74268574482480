import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {Box, Container, Stack} from "@mui/material";
import logoMobile from "../../assets/images/logo-white.png";
import {scroll} from "../../functions/scroll";
import {IG_LINK, TIKTOK_LINK} from "../../config";
import {Instagram} from "@mui/icons-material";
import {islandTheme} from "../../theme/islandTheme";
import {FaTiktok} from "react-icons/fa";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const buttons = [
    {
        id: 'lineup',
        text: 'lineup',
        opacity: 1
    },
    {
        id: 'experience',
        text: 'experiences',
        opacity: 0.63
    },
    {
        id: 'booking',
        text: 'booking',
        opacity: 0.63
    },
    {
        id: 'partners',
        text: 'partners',
        opacity: 0.63
    }
]
export const BurgerMenu = ({open, handleSetOpen}) => {
    const year = new Date().getFullYear()

    const handleClick = (id) => {
        handleSetOpen()
        setTimeout(() => scroll(id), 300)
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleSetOpen}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: "relative"}}>
                <Container maxWidth={'xl'}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{widht:'100%', py:2}}>
                        <img alt={"the island logo"} style={{width: '7.375rem'}} src={logoMobile}/>
                        <Stack direction={'column'} spacing={{xs: 0, lg: 4}} sx={{
                            opacity: '0.67',
                            display: 'flex'
                        }}>
                            <Typography variant={'body2'} fontFamily={'Public Sans'}>
                                30 MAY - 2 JUNE
                                <br/>
                                PANTELLERIA
                            </Typography>
                        </Stack>
                        <Box>
                            <IconButton size={'large'} onClick={handleSetOpen} sx={{p:0}}>
                                <CloseIcon sx={{fontSize:'3rem',color:islandTheme.palette.primary.main}}/>
                            </IconButton>
                        </Box>
                    </Stack>
                </Container>
            </AppBar>
            <Stack sx={{height:'100%'}} spacing={2} justifyContent={'space-between'}>
                <Container sx={{height:'100%', px:5}}>
                    <Stack justifyContent={'space-around'} sx={{height:'100%'}}>
                        <Stack justifyContent={'center'} alignItems={'center'} spacing={2}>
                            {buttons.map(b =>
                                <Typography key={b.id} textTransform={'uppercase'} fontWeight={700} variant={'h3'} onClick={() => handleClick(b.id)} style={{cursor: 'pointer', opacity: b.opacity}}>
                                    {b.text}
                                </Typography>
                            )}
                        </Stack>
                    </Stack>
                </Container>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={4} sx={{opacity:0.58, pb: 6}}>
                    <Typography fontFamily={'Public Sans'} variant={'caption'}>
                        © {year} ALL RIGHTS RESERVED
                    </Typography>
                    <IconButton href={IG_LINK} target={'_blank'} color={'secondary'}>
                        <Instagram/>
                    </IconButton>
                    <IconButton href={TIKTOK_LINK} target={'_blank'} color={'secondary'}>
                        <FaTiktok/>
                    </IconButton>
                </Stack>
            </Stack>
        </Dialog>
    )
}
