import React from "react";
import {Box, Dialog, DialogContent, IconButton, Typography, Stack, DialogTitle, Grid, Snackbar} from "@mui/material";
import {CopyAll, Download, Telegram, WhatsApp} from "@mui/icons-material";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ShareModal = ({open, handleSetOpen}) => {

    const [success, setSuccess] = React.useState(false)

    const programLink = window.location.href + 'full-program.pdf'

    const buttons = [
        {
            name: 'WhatsApp',
            icon: <WhatsApp/>,
            link: `whatsapp://send?text=${programLink}`
        },
        {
            name: 'Telegram',
            icon: <Telegram/>,
            link: `https://telegram.me/share/url?url=${programLink}`
        },
        {
            name: 'Copy link',
            icon: <CopyAll/>,
            link: `${programLink}`
        },
        {
            name: 'Experience & bus timetable',
            icon: <Download/>,
            link: `${programLink}`
        }
    ]

    return (
        <Dialog open={open} onClose={() => handleSetOpen(false)}>
            <Snackbar open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Link copied to clipboard
                </Alert>
            </Snackbar>
            <DialogTitle sx={{textAlign:'center', fontWeight:'bold'}}>
                Share to friends
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {buttons.map((button, index) =>
                        <Grid item xs={6} md={3} key={index} sx={{textAlign:'center'}}>
                            <Box sx={{pb:2}}>
                                <IconButton onClick={() => {
                                    if (button.name === 'Copy link') {
                                        navigator.clipboard.writeText(button.link)
                                        setSuccess(true)
                                    }
                                }} component={button.name !=='Copy link' && 'a'} href={button.name !=='Copy link' && button.link} target={'_blank'} color={''} sx={{border:'1px solid', borderRadius:'50%', width:'3.5rem', height:'3.5rem'}}>
                                    {button.icon}
                                </IconButton>
                            </Box>
                            <Typography variant={'body2'}>{button.name}</Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
  )
}
