import React, {useRef} from "react";
import {Box, Stack, Typography, useMediaQuery} from "@mui/material";
import {motion, useScroll, useTransform} from "framer-motion";
import {islandTheme} from "../theme/islandTheme";

const imageMap = [
    {
        id: 1,
        image: "experience",
        text: "experience",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasel."
    },
    {
        id: 2,
        image: "festival",
        text: "festival",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasel."
    },
    {
        id: 3,
        image: "pantelleria",
        text: "pantelleria",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasel."
    }
]

const backText = [
    {
        id:1,
        line1:'15 artists',
        top:"5%",
        left:"80%"
    },
    {
        id:2,
        line1:'more than',
        line2:'15 experiences',
        top:"45%",
        left:"5%"
    },
    {
        id:3,
        line1:'iconic landscape',
        top:"90%",
        left:"30%"
    },
]

export const TextScroll = () => {
    const containerRef = useRef(null);
    const lg = useMediaQuery(islandTheme.breakpoints.down("lg"))

    const {scrollYProgress} = useScroll({
        target: containerRef,
        offset: ["start end", "end start"]
    });

    const first = useTransform(scrollYProgress, [0, 1], lg ? ["70%", "-100%"] : ["-70%", "80%"]);
    const second = useTransform(scrollYProgress, [0, 1], lg ? ["80%", "-40%"] : ["-40%", "30%"]);
    const third = useTransform(scrollYProgress, [0, 1], lg ? ["50%", "-60%"] : ["60%", "-30%"]);

    const computeLeft = (index) => {
        switch (index) {
            case 0:
                return '50%'
            case 1:
                return '20%'
            case 2:
                return '80%'
        }
    }

    return (
        <Box ref={containerRef} sx={{position:'relative'}}>
            <Stack sx={{py: 5, overflow: 'hidden', background: 'transparent', zIndex:'2', position:'relative'}} spacing={{xs: 0, lg: -7}}>
                {imageMap.map((i, index) =>
                    <Box key={index} sx={{pb:{xs:4, lg:0}}}>
                        <Box sx={{position: 'relative', display:{xs:'flex', lg:'block'}}}>
                            <motion.div className="img-inner"
                                        style={{
                                            translateX: index === 0 ? first : index === 1 ? second : third,
                                            transform: `translateX(calc (${scrollYProgress} * 50px))`
                                        }}>

                                <Stack direction={lg ? 'row-reverse' : index === 2 ? "row-reverse" : 'row'}
                                       alignItems={'center'}
                                       spacing={3}>
                                    {!lg && <Box className={'pin-image'}>
                                        <img style={{width: '100%', opacity:0}}
                                             src={require(`../assets/images/scroll/${i.image}.png`)}/>
                                    </Box>}
                                    <Box sx={{
                                        position:'relative',
                                        fontSize: 'min(11rem, 20vw)',
                                        textTransform: 'uppercase',
                                        fontWeight: lg ? 600 : 500,
                                        color:'#F38F00',
                                        zIndex:6,
                                        fontFamily: "Blur",
                                    }}>
                                        {i.text}
                                    </Box>
                                </Stack>
                            </motion.div>
                        </Box>
                    </Box>
                )}
            </Stack>
            {/*
                backText.map((t,i) =>
                    <Box key={i} sx={{position:'absolute', left: t.left, top:t.top, zIndex:'1'}}>
                        <Typography fontWeight={600} textTransform={'uppercase'} fontFamily={'Public Sans'} sx={{opacity:'0.63'}}>
                            {t.line1}
                            <br/>
                            {t.line2}
                        </Typography>
                    </Box>
                )
            */}
        </Box>
    )
}
