import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";

export const TimeCard = ({section}) => {
    return (
        <Box sx={{height:'100%'}}>
            <Stack sx={{
                background:"#F38F00",
                borderTop: '0px',
                borderRadius: '2rem',
                mx:{xs:1, lg:0}
            }}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={{xs: 0, lg: 2}}
                       sx={{py: 2}}>
                    <Typography display={{xs: 'none', lg: 'block'}} textTransform={"uppercase"} variant={'h6'}
                                textAlign={'center'}
                                fontFamily={'Public Sans'} fontWeight={600} letterSpacing={1.2}>
                        {
                            section.type === 'activities' ? 'activities' : section.type === 'sunset-party' ? 'sunset party' : 'night dance'
                        }
                    </Typography>
                    <Box sx={{width: '1.7rem', height:'1.7rem'}}>
                        <img style={{width: '100%'}}
                             alt={"Section type icon"}
                             src={require(`../../assets/images/timetable/${section.type}.png`)}/>
                    </Box>
                </Stack>
                <Box sx={{p: 0.2, flexGrow: 1}}>
                    <Box sx={{
                        background: '#23744A',
                        overflow: 'hidden',
                        borderRadius: '0 0 2rem 2rem',
                        p: {xs: 2, lg: 4},
                        height: {xs:'94%', lg:'100%'}
                    }}>
                        {
                            (["activities", "sunset-party", "night-dance"].includes(section.type)) ?
                                <Stack spacing={2} justifyContent={'space-around'} sx={{height: '100%'}}>
                                    {section.times.map((t, i) =>
                                        <Box key={i}>
                                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                {t.radio && <Box sx={{width:'2.8rem',height:'2.8rem'}}>
                                                    <img alt={"Radio partner"} style={{width:'100%', height:'100%', objectFit:'contain'}} src={require(`../../assets/images/timetable/radio/${t.radio}.png`)}/>
                                                </Box>}
                                                <Box>
                                                    {['sunset-party', "night-dance"].includes(section.type)  &&
                                                        <Typography color={'primary'} fontWeight={600}
                                                                    textTransform={'uppercase'}
                                                                    fontFamily={'Public Sans'} letterSpacing={1.2}>
                                                            {t.stage}
                                                        </Typography>
                                                    }
                                                    <Typography
                                                        color={['sunset-party', "night-dance"].includes(section.type) ? '#EFCFB8' : 'primary'}
                                                        fontWeight={['sunset-party', "night-dance"].includes(section.type) ? 300 : 600}
                                                        fontFamily={'Public Sans'} letterSpacing={1.2}>
                                                        {t.from}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            {t.subtitle && <Typography
                                                sx={{py:1}}
                                                color={'#EFCFB8'}
                                                fontWeight={section.type === 'sunset-party' ? 300 : 600}
                                                fontFamily={'Public Sans'} letterSpacing={1.2}>
                                                {t.subtitle}
                                            </Typography>}
                                            {
                                                section.type === 'night-dance' ?
                                                    <Grid container spacing={2} sx={{pt: 2}}>
                                                        {t.djs.map(dj =>
                                                            <Grid key={dj.name} item xs={12}>
                                                                <Stack direction={'row'} sx={{
                                                                    p: 2,
                                                                    background: '#10683B',
                                                                    borderRadius: '1rem'
                                                                }} spacing={2} alignItems={'center'}>
                                                                    {dj.image !== "" && <Box sx={{width: '4.125rem'}}>
                                                                        <img style={{width: '100%', borderRadius:'50rem'}}
                                                                             alt={"Dj"}
                                                                             src={require(`../../assets/images/artists/${dj.image}.jpg`)}/>
                                                                    </Box>}
                                                                    <Box>
                                                                        <Typography fontFamily={'Public Sans'} fontWeight={'300'} sx={{opacity:'0.55'}}>
                                                                            {dj.name} {dj.surname}
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    :
                                                    <ul style={{marginBlock: '0.5rem', paddingLeft: '1rem'}}>
                                                        {t.contents.map((c, i) =>
                                                            <li key={i}>
                                                                <Typography color={'#EFCFB8'}
                                                                            fontFamily={'Public Sans'}>
                                                                    {c}
                                                                </Typography>
                                                            </li>
                                                        )}
                                                    </ul>
                                            }
                                        </Box>
                                    )}
                                </Stack>
                                :
                                <Box>
                                    <Typography color={'#EC4D2C'} fontWeight={600}
                                                fontFamily={'Public Sans'} letterSpacing={1.2}>
                                        {section.stage}
                                    </Typography>
                                    <Typography color={'#EFCFB8'} fontWeight={300}
                                                fontFamily={'Public Sans'} letterSpacing={1.2}>
                                        {section.from}
                                    </Typography>
                                </Box>
                        }
                    </Box>
                </Box>
            </Stack>
        </Box>
    )
}
