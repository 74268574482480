import React, {useState} from "react";
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import logo from '../assets/images/logo-white.png'
import logoMobile from '../assets/images/logo-white.png'
import {DragHandle, Instagram} from "@mui/icons-material";
import {scroll} from "../functions/scroll";
import {IG_LINK, TIKTOK_LINK} from "../config";
import {BurgerMenu} from "./Header/BurgerMenu";
import {islandTheme} from "../theme/islandTheme";
import {FaTiktok} from "react-icons/fa";

export const Header = () => {
    const theme = useTheme()
    const lg = useMediaQuery(theme.breakpoints.down('lg'))

    const [open, setOpen] = useState(false)

    const handleSetOpen = () => {
        setOpen(!open)
    }

    return (
        <AppBar position={'sticky'} id={'top'}>
            <BurgerMenu open={open} handleSetOpen={handleSetOpen}/>
            <Container maxWidth={'xl'}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}
                       sx={{width: '100%', py: 2}}>
                    <Stack direction={{xs: 'row', lg: 'column'}} alignItems={{xs: 'center', lg: 'start'}}
                           spacing={{xs: 2, lg: 0}}>
                        <Box sx={{width: lg ? '9.375rem' : '13.75rem'}}>
                            <img style={{width: '100%'}} src={lg ? logoMobile : logo}/>
                        </Box>
                        <Stack direction={'row'} spacing={{xs: 0, lg: 4}} sx={{
                            fontFamily: "'Public Sans', sans-serif",
                            opacity: '0.67',
                            display: {xs: 'none', lg: 'flex'}
                        }}>
                            <Typography fontFamily={'Public Sans'}>
                                PANTELLERIA 2024
                            </Typography>
                            <Typography fontFamily={'Public Sans'}>
                                30 MAY - 2 JUNE ‘24
                            </Typography>
                        </Stack>
                    </Stack>
                    <Box sx={{fontFamily: "'Public Sans', sans-serif", display: {xs: 'none', lg: 'block'}, pr:3}}>
                        <Stack direction={'row'} spacing={8}
                               sx={{textDecoration: 'none', color: 'white', opacity: '0.63'}}>
                                <span onClick={() => scroll('lineup')} style={{opacity: '1', cursor: 'pointer'}}>
                                    LINE-UP
                                </span>
                            <span onClick={() => scroll('experience')} style={{cursor: 'pointer'}}>
                                    EXPERIENCES
                                </span>
                            <span onClick={() => scroll('timetable')} style={{cursor: 'pointer'}}>
                                    TIMETABLE
                                </span>
                            <span onClick={() => scroll('get-here')} style={{cursor: 'pointer'}}>
                                    GET HERE
                                </span>
                        </Stack>
                    </Box>
                    <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{display: {xs: 'none', lg: 'flex'}}}>
                        <Box>
                            <Button variant={'contained'} onClick={() => scroll('booking')}>
                                book now
                            </Button>
                        </Box>
                        <Box>
                            <IconButton href={IG_LINK} target={'_blank'} color={'secondary'}>
                                <Instagram/>
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton href={TIKTOK_LINK} target={'_blank'} color={'secondary'}>
                                <FaTiktok/>
                            </IconButton>
                        </Box>
                    </Stack>
                    <Box sx={{display: {xs: 'block', lg: 'none'}}}>
                        <IconButton size={'large'} onClick={handleSetOpen} sx={{p:0}} color={'secondary'}>
                            <DragHandle sx={{fontSize:'3rem'}}/>
                        </IconButton>
                    </Box>
                </Stack>
            </Container>
        </AppBar>
    )
}
