export const artists = [
    {
        "name": "FLOATING POINTS",
        "date": "June 01",
        "description": "Floating Points, is British electrinuc music producer, DJ and musician. Has made some of his heaviest, most deranged tracks yet, nodding to the UK bass scene he emerged from in the late 2000s.",
        "image": "floating-points",
        "service":"spotify",
        "playlist": "https://open.spotify.com/intl-it/artist/2AR42Ur9PcchQDtEdwkv4L?si=tn8sa5pgTImQeni2l4h0tA"
    },
    {
        "name": "JOHN TALABOT",
        "date": "June 01",
        "description": "John Talabot occupies a fairly unique position in the current electronic landscape. A space he has carved himself by staying true to an artistic vision that is broad in its scope and specific in its convictions.",
        "image": "john-talabot",
        "service":"spotify",
        "playlist": "https://open.spotify.com/intl-it/artist/1YvN5uOGQkHVUUlZUcnotD?si=gRrzhjG1RbGEZ7TPv7HdoQ"
    },
    {
        "name": "MOXIE",
        "date": "May 30",
        "description": <span>Moxie is a dance music powerhouse who broadcasts her iconic voice as one of London’s most in-demand DJs and radio.
            <br/>
            In 2016 she founded the On Loop record label and events company, with the aim of promoting women in the electronic music industry,which was considered one of Mixmag’s Top 20 labels of 2017 and was nominated at the DJ Mag awards for Best Compilation.</span>,
        "image": "moxie",
        "service":"spotify",
        "playlist": "https://open.spotify.com/user/m0xie?si=850a1cb818db4f40"
    },
    {
        "name": "BRADLEY ZERO",
        "date": "May 31",
        "description": "Bradley Zero is quickly becoming the taste-maker of his generation. As founder of the Rhythm Section institution, Bradley has carved out a unique role for himself within the vibrant London scene.",
        "image": "bradley-zero",
        "service":"soundcloud",
        "playlist": "https://soundcloud.com/bradleyzero?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing "
    },
    {
        "name": "BPLAN - TOY TONICS JAM",
        "date": "May 31",
        "description": <span>Bplan is a Naeapolitan DJ and producer with an unmistakable house soul and american influences; he is a member of Toy Tonics, a German music and art collective dedicated to soul and indie influenced electronic dance music, edgy graphic design, independent fashion, DJ culture and the art of throwing parties. Toy Tonics music style ranges from underground disco and new wave funk to wild-style house and more freaky ecclectic music.</span>,
        "image": "toy-tonics-1",
        "service":"spotify",
        "playlist": "https://open.spotify.com/user/toytonics?si=52534d9197fc420d"
    },
    {
        "name": "BARBARA BOEING - TOY TONICS JAM",
        "date": "May 31",
        "description": <span>Barbara Boeing is one of the leading names on the Brazilian indipendent electronic scene; she is a member of Toy Tonics, a German music and art collective dedicated to soul and indie influenced electronic dance music, edgy graphic design, independent fashion, DJ culture and the art of throwing parties. Toy Tonics music style ranges from underground disco and new wave funk to wild-style house and more freaky ecclectic music.</span>,
        "image": "toy-tonics-2",
        "service":"spotify",
        "playlist": "https://open.spotify.com/user/toytonics?si=52534d9197fc420d"
    },
    {
        "name": "SAM RUFFILLO - TOY TONICS JAM",
        "date": "May 31",
        "description": <span>Sam Ruffillo is a producer and multi-instrumentalist; he is a member of Toy Tonics, a German music and art collective dedicated to soul and indie influenced electronic dance music, edgy graphic design, independent fashion, DJ culture and the art of throwing parties. Toy Tonics music style ranges from underground disco and new wave funk to wild-style house and more freaky ecclectic music.
        </span>,
        "image": "toy-tonics-3",
        "service":"spotify",
        "playlist": "https://open.spotify.com/user/toytonics?si=52534d9197fc420d"
    },
]
