import React, {useEffect, useState} from "react";
import {Box, Container, IconButton, Stack, Typography} from "@mui/material";
import {content} from "../../assets/data/timetable.js";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {TimetableDay} from "./TimetableDay";
import ReactTextTransition, {presets} from "react-text-transition";
import {useAnimation, motion, useAnimationControls} from "framer-motion";
import {useInView} from "react-intersection-observer";

export const TimetableDesktop = () => {
    const [day, setDay] = useState(0)
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const controls = useAnimationControls()
    const {ref, inView, entry} = useInView({threshold:0.6, triggerOnce:true});

    const slideVariants = {
        start: {marginLeft: '2rem', transition:{duration:0.2, delay: 0.3}},
        middle: { marginLeft:'-8rem', transition:{duration:0.3}},
        end: {marginLeft: 0, transition:{duration:0.2}}
    }

    useEffect(() => {
        if (inView || entry) {
            sequence()
        }
    }, [controls, inView]);

    const sequence = async () => {
        await controls.start("middle")
        await controls.start("start")
        return await controls.start("end")
    }

    const handleSetDay = (i) => {
        console.log(day)

        setDay(day + i)
    }

    return (
        <Box>
            <Container maxWidth={'lg'} sx={{pb: 4}}>
                <Box sx={{width: '100%', background: '#23744A', py: 2, mb: 2, mt: 6, borderRadius: '50rem', position:'relative !important', zIndex:'1000'}}>
                    <Stack justifyContent={'center'} alignItems={'center'} spacing={10} direction={'row'} sx={{zIndex:'100'}}>
                        <Box>
                            <IconButton ref={navigationPrevRef} color={'primary'} disabled={!content[day - 1]}>
                                <ArrowBackIosRoundedIcon/>
                            </IconButton>
                        </Box>
                        <Typography textTransform={'uppercase'} textAlign={'center'} fontWeight={700} variant={'h5'}>
                            {`${content[day].day}`.split(" ")[0]}&nbsp;&nbsp;
                            <ReactTextTransition inline>
                                {`${content[day].day}`.split(" ")[1]}
                            </ReactTextTransition>
                        </Typography>
                        <Box>
                            <IconButton ref={navigationNextRef} color={'primary'} disabled={!content[day + 1]}>
                                <ArrowForwardIosRoundedIcon/>
                            </IconButton>
                        </Box>
                    </Stack>
                </Box>
            </Container>
            <Box ref={ref}>
                <Swiper
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                    onActiveIndexChange={e => setDay(e.activeIndex)}
                    modules={[Navigation]}
                    slidesPerView={1.1}
                    spaceBetween={0}
                    centeredSlides={true}
                    initialSlide={day}
                    grabCursor={true}
                    pagination={{
                        clickable: true,
                    }}
                    className="mySwiper"
                    breakpoints={{
                        1930: {
                            slidesPerView: 1.3,
                            spaceBetween: 10
                        },
                        1790: {
                            slidesPerView: 1.23,
                            spaceBetween: 10
                        },
                        1700: {
                            slidesPerView: 1.15,
                            spaceBetween: 10
                        },
                        1530: {
                            slidesPerView: 1.1,
                            spaceBetween: 10
                        },
                        1200: {
                            slidesPerView: 1.05,
                            spaceBetween: 10
                        }
                    }}>
                    {content.map((c, i) =>
                        <SwiperSlide key={i}>
                            <motion.div
                                variants={slideVariants}
                                initial={'end'}
                                animate={controls}
                                transition={{type:'spring', stiffness:0}}
                            >
                                <TimetableDay content={c}/>
                            </motion.div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </Box>
        </Box>
    )
}
