import React from "react";
import {Box, Typography, Container, Grid, Stack} from "@mui/material";
import {faq} from '../assets/data/faq.js'
import {FaqContent} from "./Faq/FaqContent";

export const Faq = () => {
    const [open, setOpen] = React.useState(null)

    const handleOpen = (index) => {
        setOpen(open === index ? null : index)
    }

    return (
        <Box sx={{py: 10}}>
            <Container maxWidth={'md'}>
                <Typography textAlign={'center'} variant={'h3'} fontFamily={'Blur'} fontWeight={{xs: 700, lg: 500}}>
                    FAQ
                </Typography>
                <Grid container rowSpacing={2} columnSpacing={3} sx={{pt: 5}}>
                    <Grid item xs={12} lg={6}>
                        <Stack sx={{width:'100%'}} spacing={2}>
                            {faq.slice(0, 5).map((f, i) =>
                                <Box key={i}>
                                    <FaqContent faq={f} handleOpen={handleOpen} index={f.id} open={open === f.id}/>
                                </Box>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Stack sx={{width:'100%'}} spacing={2}>
                            {faq.slice(5, 9).map((f, i) =>
                                <Box key={i}>
                                    <FaqContent faq={f} handleOpen={handleOpen} index={f.id} open={open === f.id}/>
                                </Box>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
