import React from "react";
import {Box, Button, Typography, useMediaQuery} from "@mui/material";

import "swiper/css";
import {TimetableDesktop} from "./Timetable/TimetableDesktop";
import {islandTheme} from "../theme/islandTheme";
import {TimetableMobile} from "./Timetable/TimetableMobile";

export const Timetable = ({handleSetOpen}) => {
    const matches = useMediaQuery(islandTheme.breakpoints.up('lg'))
    return (
        <Box sx={{py:10}} id={'timetable'}>
            <Typography textAlign={'center'} variant={'h2'} fontFamily={'Blur'} fontWeight={{xs: 700, md: 500}} lineHeight={0.9} sx={{pb:2}}>
                TIMETABLE
            </Typography>
            {matches ? <TimetableDesktop/> : <TimetableMobile/>}
            {/*<Box sx={{pt: {xs: 5, md: 9}, textAlign: 'center'}}>
                <Button variant={'outlined'} color={'secondary'} onClick={handleSetOpen}>
                    SHARE TO FRIENDS
                </Button>
            </Box>*/}
        </Box>
    )
}
