import React, {useState} from "react";
import {Box, Button, Container, Stack, useMediaQuery} from "@mui/material";
import {islandTheme} from "../theme/islandTheme";
import {VolumeOffRounded, VolumeUpRounded} from "@mui/icons-material";

const VideoContent = ({muted, handleMute}) => {
    return(
        <Box sx={{position:'relative'}}>
            <Button onClick={handleMute} sx={{position:'absolute', right:'1rem', bottom:'1rem', zIndex:'99'}} color={'secondary'}>
                {muted ? 'play with audio' : 'mute'}&nbsp; &nbsp;<Stack justifyContent={'center'} alignItems={'center'} sx={{border:'1px solid white', borderRadius:'5rem', width:'2.5rem', height:'2.5rem'}}>{muted ? <VolumeUpRounded/> : <VolumeOffRounded/>}</Stack>
            </Button>
            <div className={'video-padding'}>
                <Box className={'video-outer'}>
                    <video controls={false} autoPlay loop muted={muted} playsInline className="claim-video"
                           src={require('../assets/video/video-header.mp4')}></video>
                </Box>
            </div>
        </Box>
    )
}

export const VideoBox = () => {
    const matches = useMediaQuery(islandTheme.breakpoints.down('lg'))
    const [muted, setMuted] = useState(true)

    const handleMute = () => {
        setMuted(!muted)
    }
    return (
        <Box sx={{position: 'relative', mt: 0, zIndex: 1}}>
            {
                !matches ?
                    <Container maxWidth={'xl'}>
                        <VideoContent muted={muted} handleMute={handleMute}/>
                    </Container>
                    :
                    <Box sx={{height: '100%', width: '100%'}}>
                        <VideoContent muted={muted} handleMute={handleMute}/>
                    </Box>
            }
        </Box>
    )
}
