import React from "react";
import {Box, Container, Stack, Typography} from "@mui/material";

export const HowToGet = () => {
    return (
        <Box id={'get-here'} sx={{
            background: {xs: '#B5D9D5', lg: '#BDE4DD'},
            minHeight: '20rem',
            color: '#0B053E',
            textAlign: 'center',
            py: 5,
            position: 'relative'
        }}>
            <Container maxWidth={'lg'} sx={{position: 'relative'}}>
                <Box sx={{position: 'relative', zIndex: 2, py: 5}}>
                    <Typography sx={{pb: 7}} textTransform={'uppercase'} textAlign={'center'} variant={'h3'} fontFamily={'Blur'}
                                fontWeight={{xs: 700, lg: 500}} lineHeight={1}>
                        how to
                        <br/>
                        REACH US
                    </Typography>
                    <Stack spacing={{xs: 4, lg: 0}} direction={{xs: 'column', lg: 'row'}} alignItems={'center'}
                           justifyContent={{xs: 'center', md: 'space-between'}} sx={{fontFamily: 'Public Sans'}}>
                        <Box sx={{width: 'min(100%, 30rem)', display: {xs: 'block', lg: 'none'}}}>
                            <img width={'100%'} src={require('../assets/images/how-to-get/bkg-mobile.jpg')}/>
                        </Box>
                        <Box sx={{width: 'min(25rem, 100%)', px: 1}}>
                            <Typography fontWeight={700} fontFamily={'Public Sans'} variant={'h6'}>
                                FROM SICILY
                            </Typography>
                            <Typography fontWeight={400} fontFamily={'Public Sans'} sx={{pt: 2}}
                                        lineHeight={{xs: 1.3, lg: 1.6}}>
                                Ferries from Trapani, available for purchase on <a href={'https://carontetourist.it'}
                                                                                   target={'_blank'}>Caronte & Tourist /
                                Siremar</a> (for cars and passengers transport)
                                <br/>
                                <br/>
                                Direct flights from Catania, Palermo, Trapani, available for purchase on <a
                                href={'https://dat.dk/?___store=dat_it'} target={'_blank'}>Dat</a>
                            </Typography>
                        </Box>
                        <Box sx={{width: 'min(25rem, 100%)', px: 1}}>
                            <Typography fontWeight={700} fontFamily={'Public Sans'} variant={'h6'}
                                        textTransform={'uppercase'}>
                                From the main Italian cities
                            </Typography>
                            <Typography fontWeight={400} fontFamily={'Public Sans'} sx={{pt: 2}}
                                        lineHeight={{xs: 1.3, lg: 1.6}}>
                                Direct flights to Palermo, Catania, Trapani + direct flights to Pantelleria available
                                for purchase on <a href={'https://dat.dk/?___store=dat_it'} target={'_blank'}>Dat</a>
                                <br/>
                                <br/>
                                Direct flights from Milan Bergamo, Bologna, Venice and Verona, available from June 1 for
                                purchase on <a href={'https://www.volotea.com/it/'} target={'_blank'}>Volotea</a>
                                <br/>
                                <br/>
                                Direct flights from Roma available June 1 for purchase on <a
                                href={'https://www.ita-airways.com/it_it/'} target={'_blank'}>Ita Airways</a>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{
                    display: {xs: 'none', lg: 'block'},
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    zIndex: 0
                }}>
                    <img style={{width: '100%', height: '100%', objectFit: 'contain'}}
                         src={require('../assets/images/how-to-get/bkg.jpg')}/>
                </Box>
            </Container>
        </Box>
    )
}
