import React, {useState} from "react";
import {Box, Collapse, Stack, Typography} from "@mui/material";
import {ExpandMoreRounded, ExpandLessRounded} from "@mui/icons-material";
import DOMPurify from 'dompurify';

export const FaqContent = ({faq, handleOpen, open, index}) => {
    return (
        <Box className={'faq-box'} sx={{border:'1px solid #EFCFB8', borderRadius:'50px', py:3, px:4}}>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{width:'100%', cursor:'pointer'}} onClick={() => handleOpen(index)}>
                <Typography color={'#EFCFB8'}>
                    {faq.question}
                </Typography>
                {open ? <ExpandLessRounded sx={{color:'#EFCFB8'}}/> : <ExpandMoreRounded sx={{color:'#EFCFB8'}}/> }
            </Stack>
            <Collapse in={open}>
                <Box sx={{maxWidth:'90%', py:3}}>
                    {/*<Typography color={'#EFCFB8'} sx={{opacity: '0.7'}}
                                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(faq.answer)}}/>*/}
                    {faq.answer}
                </Box>
            </Collapse>
        </Box>
    )
}
