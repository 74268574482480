import React, {useRef, useState} from "react";
import {Box, Button, Container, Grid, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {ArtistCard} from "./Artists/ArtistCard";
import {hotels} from "../assets/data/hotels";
import {HotelCard} from "./Hotels/HotelCard";
import {islandTheme} from "../theme/islandTheme";

export const Hotels = () => {
    const navigationNextRef = useRef(null)
    const navigationPrevRef = useRef(null)
    const [index, setIndex] = useState(0)

    const theme = useTheme();
    const large = useMediaQuery(theme.breakpoints.up("lg"));

    return (
        <Box sx={{py: 10}}>
            <Typography textAlign={'center'} variant={'h3'} fontFamily={'Blur'} fontWeight={{xs: 600}}>
                ACCOMMODATION
            </Typography>
            <Box sx={{pt: 10, display: {xs: 'none', lg: 'block'}}}>
                <Container maxWidth={'xl'}>
                    <Grid container spacing={4}>
                            {hotels.map((h, i) =>
                                <Grid item xs={4}>
                                    <HotelCard hotel={h}/>
                                </Grid>
                            )}
                    </Grid>
                </Container>
                <Container maxWidth={'xl'} sx={{pt: 5}}>

                        <Box sx={{textAlign:'center', width:'100%'}}>
                            <Button variant={'outlined'} color={'secondary'}
                                    href={'https://www.pantelleriaisland.it/the-island-festival.html'}
                                    target={'_blank'}>
                                AND MORE
                            </Button>
                        </Box>

                </Container>
            </Box>
            <Box sx={{pt: 8, display: {xs: 'block', lg: 'none'}}}>
                <Grid container component={Container} spacing={{xs: 6, md: 1}}>
                    {hotels.map(h =>
                        <Grid item xs={12} md={4} key={h.id}>
                            <HotelCard hotel={h}/>
                        </Grid>
                    )}
                </Grid>
                <Box sx={{textAlign: 'center', pt:6, width:'100%'}}>
                    <Button variant={'outlined'} color={'secondary'}
                            href={'https://www.pantelleriaisland.it/the-island-festival.html'} target={'_blank'}>
                        AND MORE
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
