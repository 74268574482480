export const content = [
    {
        "day": "may 30",
        "sections": [
            {
                "type": "activities",
                "times": [
                    {
                        "from": "FROM 10 A.M.",
                        "contents": [
                            "Galeone Adriana by Fuzetea",
                            "MINI Discovering Experience",
                            "Paloma Boat Party by Altos Tequila",
                        ]
                    },
                    {
                        "from": "FROM 3 P.M.",
                        "contents": [
                            "MINI Discovering Experience",
                            "Benikulà Sauna Experience by Fuzetea",
                            "Venus Volcanics Muds Experience by FuzeTea",
                            "Kayak by Fuzetea",
                            "Pool Party",
                        ]
                    },
                    {
                        "from": "FROM 4 P.M.",
                        "contents": [
                            "Galeone Adriana by Fuzetea (16:30)",
                            "Paloma Boat Party by Altos Tequlla",
                            "Passito Tasting Experience",
                        ]
                    },
                    {
                        "from": "FROM 5 P.M.",
                        "contents": [
                            "Sup Between Arch and Stacks by FuzeTea",
                            "Vinyasa Yoga and Pranayama Respiration with Giada Merli",
                        ]
                    },
                    {
                        "from": "FROM 6 P.M.",
                        "contents": [
                            "Tibetan Bells with Donata Meduri by Del Maguey Mezcal",
                        ]
                    },
                    {
                        "from": "FROM 7 P.M.",
                        "contents": [
                            "Malfy Sunset Hour by Malfy Gin",
                        ]
                    },
                    {
                        "from": "FROM 8 P.M.",
                        "contents": [
                            "MINI New Horizons"
                        ]
                    },

                ]
            },
            {
                "type": "sunset-party",
                "times": [
                    {
                        "stage": "MURSIA",
                        "from": "FROM 4 P.M.",
                        "subtitle": "",
                        "contents": ['Wusketti'],
                        "radio": ""
                    },
                    {
                        "stage": "KAYAKAYA",
                        "from": "FROM 7 P.M.",
                        "subtitle": "",
                        "contents": ['Take it Easy'],
                        "radio": ""
                    },
                ]
            },
            {
                "type": "night-dance",
                "times": [
                    {
                        "stage": "SESIVENTI",
                        "from": "FROM 10 P.M.",
                        "djs": [
                            {
                                "name": "Tayga",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "Dirty Channels",
                                "surname": "",
                                "image": "dirty-channels"
                            },
                            {
                                "name": "Moxie",
                                "surname": "",
                                "image": "moxie"
                            },
                        ]
                    }
                ]
            }
        ]
    },
    {
        "day": "MAY 31",
        "sections": [
            {
                "type": "activities",
                "times": [
                    {
                        "from": "FROM 10 A.M.",
                        "contents": [
                            "Galeone Adriana by Fuzetea",
                            "MINI Discovering Experience",
                            "Paloma Boat Party by Altos Tequila",
                        ]
                    },
                    {
                        "from": "FROM 11 A.M.",
                        "contents": [
                            "Venus volcanic muds experience by Fuzetea",
                            "Gadir hot springs experience by Fuzetea",
                            "Vinyasa Yoga wih Giulia Peditto",
                            "Meditation with Rosita Pompili",
                        ]
                    },
                    {
                        "from": "From 1 P.M.",
                        "contents": [
                            "Galeone Adriana FuzeTea"
                        ]
                    },
                    {
                        "from": "FROM 2:30 P.M.",
                        "contents": [
                            "Mindful Dance with Donata Meduri by Del Maguey Mezcal"
                        ]
                    },
                    {
                        "from": "FROM 3 P.M.",
                        "contents": [
                            "Galeone Adriana FuzeTea Special Guest Giulia Peditto",
                            "MINI Discovering Experience",
                            "Benikula’ Sauna Experience by FuzeTea",
                            "Venus Volcanics Muds Experience By Fuzetea",
                            "Kayak by FuzeTea",
                            "Capers and Local Products Tasting Experience",
                            "Pool Party",
                        ]
                    },
                    {
                        "from": "FROM 4 P.M.",
                        "contents": [
                            "Paloma Boat Party by Altos Tequila",
                            "Walk with Donkeys by Fuzetea",
                            "Snorkeling with Guide by FuzeTea",
                            "Gadir Hot Springs Experience by FuzeTea",
                        ]
                    },
                    {
                        "from": "FROM 5 P.M.",
                        "contents": [
                            "Vinyasa Yoga and Pranayama Respiration with Rosita Pompili",
                            "Benikulà Sauna Experience by FuzeTea",
                        ]
                    },
                    {
                        "from": "FROM 7 P.M.",
                        "contents": [
                            "Malfy Sunset Hour by Malfy Gin"
                        ]
                    },
                    {
                        "from": "FROM 8 P.M.",
                        "contents": [
                            "MINI New Horizons"
                        ]
                    },

                ]
            },
            {
                "type": "sunset-party",
                "times": [
                    {
                        "stage": "MURSIA",
                        "from": "FROM 3 P.M.",
                        "subtitle": "",
                        "contents": ['Thanksmate'],
                        "radio": ""
                    },
                    {
                        "stage": "KAYAKAYA",
                        "from": "FROM 7 P.M.",
                        "subtitle": "",
                        "contents": ['Tayga'],
                        "radio": ""
                    },
                ]
            },
            {
                "type": "night-dance",
                "times": [
                    {
                        "stage": "SESIVENTI",
                        "from": "FROM 9 P.M.",
                        "djs": [
                            {
                                "name": "Ilaria GR",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "Garcon de Rue",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "Toy Tonics",
                                "surname": "",
                                "image": "toy-tonics-trio"
                            },
                            {
                                "name": "Thanksmate",
                                "surname": "",
                                "image": ""
                            },
                        ]
                    },
                    {
                        "stage": "ALTAMAREA",
                        "from": "FROM 10 P.M.",
                        "djs": [
                            {
                                "name": "Ciancio",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "Della Palma",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "Bradley Zero",
                                "surname": "",
                                "image": "bradley-zero"
                            },
                        ]
                    }
                ]
            }
        ]
    },
    {
        "day": "june 1",
        "sections": [
            {
                "type": "activities",
                "times": [
                    {
                        "from": "FROM 10 A.M.",
                        "contents": [
                            "Galeone Adriana by FuzeTea",
                            "MINI Discovering Experience",
                            "Paloma Boat Party by Altos Tequila",
                            "Trekking and Sauna in Favare by FuzeTea",
                        ]
                    },
                    {
                        "from": "FROM 11 A.M.",
                        "contents": [
                            "Venus Volcanics Muds Experience by FuzeTea",
                            "Sup Between Arch and Stacks By FuzeTea",
                        ]
                    },
                    {
                        "from": "FROM 12 A.M.",
                        "contents": [
                            "Meditation with Rosita Pompili",
                            "Gadir Hot Springs Experience by FuzeTea",
                        ]
                    },
                    {
                        "from": "From 1 P.M.",
                        "contents": [
                            "Galeone Adriana by FuzeTea"
                        ]
                    },
                    {
                        "from": "From 2:30 P.M.",
                        "contents": [
                            "Cacao Ceremony with Donata Meduri by Del Maguey Mezcal"
                        ]
                    },
                    {
                        "from": "FROM 3 P.M.",
                        "contents": [
                            "Galeone Adriana FuzeTea Special Guest Giulia Peditto",
                            "MINI Discovering Experience",
                            "Benikula’ Sauna Experience by FuzeTea",
                            "Snorkeling with Guide by FuzeTea",
                            "Passito Tasting Experience",
                            "Pool Party",
                        ]
                    },
                    {
                        "from": "FROM 4 P.M.",
                        "contents": [
                            "Paloma Boat Party by Altos Tequila",
                            "Kayak by FuzeTea",
                            "Guided Bicycle Tour Montagna Grande by FuzeTea",
                        ]
                    },
                    {
                        "from": "FROM 6 P.M.",
                        "contents": [
                            "Free Movement and Meditation with Giulia Peditto, Rosita Pompili and Giada Merli",
                        ]
                    },
                    {
                        "from": "FROM 7 P.M.",
                        "contents": [
                            "Malfy Sunset Hour by Malfy Gin"
                        ]
                    },
                    {
                        "from": "FROM 8 P.M.",
                        "contents": [
                            "MINI New Horizons"
                        ]
                    },

                ]
            },
            {
                "type": "sunset-party",
                "times": [
                    {
                        "stage": "KAYAKAYA",
                        "from": "FROM 7 P.M.",
                        "subtitle": "",
                        "contents": ['Ilaria GR'],
                        "radio": ""
                    },
                    {
                        "stage": "SESIVENTI",
                        "from": "FROM 7 P.M.",
                        "subtitle": "",
                        "contents": ['Lovecast'],
                        "radio": ""
                    },
                ]
            },
            {
                "type": "night-dance",
                "times": [
                    {
                        "stage":"SESIVENTI",
                        "from":"FROM 10 P.M.",
                        "djs": [
                            {
                                "name": "Nunzio Borino",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "DJLMP",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "John Talabot",
                                "surname": "",
                                "image": "john-talabot"
                            },

                        ]
                    },
                    {
                        "stage": "ALTAMAREA",
                        "from": "FROM 10 P.M.",
                        "djs": [
                            {
                                "name": "Bulma Brief",
                                "surname": "",
                                "image": ""
                            },
                            {
                                "name": "Floating Points",
                                "surname": "",
                                "image": "floating-points"
                            },
                            {
                                "name": "Bugsy",
                                "surname": "",
                                "image": ""
                            },

                        ]
                    }
                ]
            }
        ]
    },
    {
        "day": "June 2",
        "sections": [
            {
                "type": "activities",
                "times": [
                    {
                        "from": "FROM 10 A.M.",
                        "contents": [
                            "Galeone Adriana by FuzeTea",
                            "MINI Discovering Experience",
                            "Paloma Boat Party by Altos Tequila",
                        ]
                    },
                    {
                        "from": "FROM 11 A.M.",
                        "contents": [
                            "Benikulà Sauna Experience by FuzeTea",
                            "Capers and Local Products Tasting Experience",
                            "Tibetan Bells with Donata Meduri by Del Maguey Mezcal",
                        ]
                    },
                    {
                        "from": "FROM 12 A.M.",
                        "contents": [
                            "Venus Volcanics Muds Experience by FuzeTea"
                        ]
                    },
                    {
                        "from": "FROM 3 P.M.",
                        "contents": [
                            "Galeone Adriana FuzeTea Special Guest Giulia Peditto",
                            "MINI Discovering Experience",
                            "Benikula’ Sauna Experience by FuzeTea",
                        ]
                    },
                    {
                        "from": "FROM 4 P.M.",
                        "contents": [
                            "Paloma Boat Party by Altos Tequila",
                            "Snorkeling with Guide by FuzeTea",
                            "Meditation with Giada Merli",
                ]
                    },
                    {
                        "from": "FROM 7 P.M.",
                        "contents": [
                            "Malfy Sunset Hour by Malfy Gin"
                        ]
                    },
                    {
                        "from": "FROM 8 P.M.",
                        "contents": [
                            "MINI New Horizons"
                        ]
                    },

                ]
            }
        ]
    },

]
