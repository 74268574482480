import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {PartnerBlock} from "./Partners/PartnerBlock";
import content from '../assets/data/partners.json'

const p1 = {
    title: "TITLE PARTNER",
    lg: 2.2,
    md: 6,
    xs: 6,
    list: [
        {
            "image":"fuzetea.jpg",
            "link":""
        }
    ]
}

const p2 = {
        "id": "sustainability",
        "title": "SUSTAINABILITY PARTNER",
        "lg": 2.2,
        "md": 6,
        "xs": 6,
        "list": [
            {
                "image":"mini.jpg",
                "link":""
            },
        ]
}

const p3 = {
    "id": "sustainability2",
    "title": "",
    "lg": 2.2,
    "md": 6,
    "xs": 6,
    "list": [
        {
            "image":"plenitude.jpg",
            "link":""
        }
    ]
}

export const Partners = () => {
    return (
        <Box sx={{background:'#fff', py:10}} id={'partners'}>
            <Typography color={'#005E2E'} textAlign={'center'} variant={'h3'} fontFamily={'Blur'} fontWeight={{xs:700}}>
                PARTNERS
            </Typography>
            <Box sx={{width:'min(100%, 100rem)', margin:'auto',px: {xs:2, md:3}, pt:3}}>
                <Grid container justifyContent={'center'} spacing={2} sx={{pb:2}}>
                    <PartnerBlock partner={p1}/>
                </Grid>
                <Grid container justifyContent={'center'} spacing={2}>
                    <PartnerBlock partner={p2}/>
                </Grid>
                <Grid container justifyContent={'center'} spacing={2} sx={{pb:14}}>
                    <PartnerBlock partner={p3}/>
                </Grid>
                <Grid container justifyContent={'center'} spacing={2}>
                    {content.map( (p, i) =>
                        <PartnerBlock key={i} partner={p}/>
                    )}
                </Grid>
            </Box>
        </Box>
    )
}
