import React from "react";
import {Box, Typography} from "@mui/material";

export const ExperienceCard = ({content, index, handleOpen}) => {
    return (
        <Box onClick={() => handleOpen(true, index)}>
            <Box sx={{width: '100%', position: 'relative'}}>
                <img style={{width: '100%', borderRadius:'1rem'}}
                     alt={"Experience"}
                     src={require(`../../assets/images/experiences/${content.image}.jpg`)}/>
            </Box>
            <Box sx={{minHeight: {xs:'auto', md:'9.5rem'}, pr:2.5}}>
                <Typography fontWeight={700} variant={'h4'} sx={{pt: 2}} color={'#005E2E'}>
                    {content.name}
                </Typography>
            </Box>
            <Box>
                <Typography fontWeight={500} variant={'h6'} color={'#005E2E'}>
                    {content.location}
                </Typography>
            </Box>
        </Box>
    )
}
