import React from "react";
import {Box, Container, Typography, Grid} from "@mui/material";

export const CarbonNeutral = () => {
    return (
        <Box sx={{py: 10, background:'#FFF4E7'}}>
            <Container maxWidth={'md'}>
                <Typography color={'#005E2E'} textAlign={'center'} variant={'h3'} fontFamily={'Blur'} fontWeight={{xs:700}}>
                    SUSTAINABILITY
                </Typography>
                <Grid container spacing={12} alignItems={'center'} sx={{pt:4}}>
                    <Grid item xs={12} md={5}>
                        <img style={{width:'100%', borderRadius:'1rem'}} src={require('../assets/images/sustainability/Sustainability.jpg')}/>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography fontFamily={'Public Sans'} fontWeight={'600'} color={'#005E2E'}>
                            The Island is committed to creating a positive impact, paying particular attention to environmental sustainability.
                            <br/>
                            Thanks to Plenitude, The Island donated a photovoltaic panel system to Pantelleria which will remain there for the next 30 years and which will once again energize the festival this year.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
